import React from "react";
import { Link } from "react-router-dom";
import './Update.css';

export default function Update() {
    return (
        <div className="update-section">
            <div className="section-heading text-center">
                <h2 style={{ color: '#3c096c' }}>Recent Updates</h2>
            </div>
            <div className="update-content container">
                <ul className="update-box">
                    <li className="list">Grab an <i>Ebook </i><u><a href="https://pritisolanki.gumroad.com/l/prompt-toolkit-for-website-design" className="link" rel="noreferrer" target="_blank">Prompt to launch your dream website</a> !!</u>, Nov 2024</li>
                    <li className="list">First Techsolvance session delivered! <Link to ="https://purplespot.ink/#features" className="link" target="_blank">PurpleSpot.ink</Link>, Oct 2024</li>
                    <li className="list">Thrilled to have industry expert joining me on <Link to ="https://purplespot.ink" className="link" target="_blank">PurpleSpot.ink</Link>, Oct 2024</li>
                    <li className="list">Launched <Link to ="https://purplespot.ink/" className="link" target="_blank">PurpleSpot.ink</Link> - Make discussion happen!, Sep 2024</li>
                    <li className="list">Completed Certification - AI Solution Architect, Sep 2024</li>
                    <li className="list">LangChain-based chatbot deployed on Hugging Face Spaces. Access information from PDFs, blogs, and databases through conversation. Jun 2024</li>
                    <li className="list">ChatGPT based article generator. Jun 2024</li>
                </ul>
                <ul className="update-box">
                    <li className="list">Hackathon Entry:  <Link to="https://devpost.com/software/geminus" className="link" target="_blank">Geminus - driving empowered by Gemini AI</Link> configured on Vertex platform, deployed on Google Cloud </li>
                    <li className="list">Develop app on AWS PartyRock, <Link to="https://devpost.com/software/polyai" className="link" target="_blank">PolyAI - Your Creative Assistant for Boundless Inspiration</Link></li>
                    <li className="list">Deployed OpenAI custom AI bot on HuggingFace Spaces!, Feb 2024</li>
                    <li className="list">Finished - <Link to="https://executiveeducation.wharton.upenn.edu/online-learning/self-paced-online-programs/business-analytics-certificate/" className="link" target="_blank">Business Analytics: From Data to Insights</Link>, Feb 2024</li>
                    <li className="list">Setup a Voiceflow AI chatbot for course information lookup, Feb 2024</li>
                    <li className="list">Integrate <i>"Spot"</i> demo AI bot using chatbase platform @pritisolanki.com, Feb 2024</li>
                </ul>
            </div>
        </div>
    );
}