import React from "react";
import "./Skills.css";

export default function Skill() {
    return (
        <div className="skills-content" id="skill">
            <div className="section-heading text-center">
                <h2 style={{ color: '#3c096c' }}>Skills</h2>
            </div>
            <div className="skills-box container">
                <div className="table-box" style={{ marginRight: '70px' }}>
                    <table style={{ backgroundColor: '#ECFCFF' }}>
                        <tbody>
                            <tr>
                                <td>Architecture</td>
                                <td>Microservices, Service-Oriented Architecture</td>
                            </tr>
                            <tr style={{ backgroundColor: '#5EDFFF' }}>
                                <td>Cloud</td>
                                <td>AWS, Azure, GCP, Heroku, Netlify</td>
                            </tr>
                            <tr>
                                <td>Databse</td>
                                <td>Mysql, Postgres, MongoDB, Neo4j</td>
                            </tr>
                            <tr style={{ backgroundColor: '#5EDFFF' }}>
                                <td>Tools</td>
                                <td>JIRA, Visio, Zapier, Clickfunnel, Salesforce, Travis, Codacy, Docker</td>
                            </tr>
                            <tr>
                                <td>Language</td>
                                <td>Node.js, Python, Typescript, PHP 5.3+, SQL, XML, HTML, Jquery, Javascript, Typescript</td>
                            </tr>
                            <tr style={{ backgroundColor: '#5EDFFF' }}>
                                <td>API Integration</td>
                                <td>Nylas, Kloudless, Face Rekognition, Tesseract, NLP Classifier</td>
                            </tr>
                            <tr>
                                <td>Framework</td>
                                <td>Express, Bootstrap, Tailwind CSS, Svelte, Streamlit</td>
                            </tr>
                            <tr style={{ backgroundColor: '#5EDFFF' }}>
                                <td>CMS</td>
                                <td>Joomla, Wordpress, Kontent.ai</td>
                            </tr>
                            <tr>
                                <td>AI</td>
                                <td>AI chatbot (voiceflow, chatbase), AI cloud services, Generative AI</td>
                            </tr>  
                            <tr style={{ backgroundColor: '#5EDFFF' }}>
                                <td>LLM Models</td>
                                <td>OpenAI GPT, Gemini, Claude</td>
                            </tr>  
                            <tr>
                                <td>Machine Learning Platform</td>
                                <td>Hugging Face, Vertex AI</td>
                            </tr>                                                        
                        </tbody>
                    </table>
                </div>
                <div className="table-box" style={{ marginRight: '70px' }} >
                    <table style={{ backgroundColor: '#5EDFFF' }}>
                        <tbody>
                            <tr>
                                <td>Content</td>
                                <td>Structured Content, Content Modeling, Content Strategy, Publishing Workflows</td>
                            </tr>
                            <tr style={{ backgroundColor: '#ECFCFF' }}>
                                <td>Customer Education</td>
                                <td>Presentation, Storytelling, Communication, Technical Demo</td>
                            </tr>
                            <tr>
                                <td>Design</td>
                                <td>Wireframe, Mockup, UI/UX, Design Thinking</td>
                            </tr>
                            <tr style={{ backgroundColor: '#ECFCFF' }}>
                                <td>Stakeholders Management </td>
                                <td>Communication, Storytelling, Cross Team Collaboration</td>
                            </tr>
                            <tr>
                                <td>AI UI/UX</td>
                                <td>Midjourney, Gamma</td>
                            </tr>                               
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}